import { FC } from 'react';
import IconProps from './IconProps';

const NumbersIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 17.6638V9.66382H2V7.66382H6V17.6638H4ZM22 15.6638C22 16.7738 21.1 17.6638 20 17.6638H16V15.6638H20V13.6638H18V11.6638H20V9.66382H16V7.66382H20C20.5304 7.66382 21.0391 7.87453 21.4142 8.2496C21.7893 8.62468 22 9.13339 22 9.66382V11.1638C22 11.5616 21.842 11.9432 21.5607 12.2245C21.2794 12.5058 20.8978 12.6638 20.5 12.6638C20.8978 12.6638 21.2794 12.8219 21.5607 13.1032C21.842 13.3845 22 13.766 22 14.1638V15.6638ZM14 15.6638V17.6638H8V13.6638C8 12.5538 8.9 11.6638 10 11.6638H12V9.66382H8V7.66382H12C12.5304 7.66382 13.0391 7.87453 13.4142 8.2496C13.7893 8.62468 14 9.13339 14 9.66382V11.6638C14 12.7738 13.1 13.6638 12 13.6638H10V15.6638H14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NumbersIcon;
